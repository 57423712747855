<template>
    <div class="batch-order">
        <div class="mb-8">{{ title }}</div>
        <div class="sub-tip">{{ subTitle }}</div>
        <div v-for="(item, index) in rolateList" :key="item.id" class="rolate-deliver">
          <div v-if="index === 0" class="header mb">
            <span class="sub-title">{{ $t('关联订单') }}</span>
            <div class="common-t-r">
              <van-checkbox v-model="isCheckAll" shape="square" :indeterminate="isIndeterminate" @click="checkAllChange">全选</van-checkbox>
            </div>
          </div>
          <div class="header">
            <span>
              <div>交付单号： {{ item.dcosOrderId }}</div>
              <div>VIN： {{ item.vin }}</div>
            </span>
            <van-checkbox :value="selectIds.includes(item.id)" shape="square" @click="onChange(item)"></van-checkbox>
          </div>
          <!-- <BasicComponent :details="item" :show-header="false" :show-labels="false"/> -->
        </div>
        <div v-if="footerBtnShow" class="footer">
          <van-button class="btn" native-type="button" @click="onCancel">{{ $t('取消') }}</van-button>
          <van-button class="btn submit" native-type="button" @click="onConfirm">{{ confirmBtnText }}</van-button>
        </div>
    </div>
</template>
<script>
import BasicComponent from './basic-component.vue'
export default {
  components:{ BasicComponent },
  props: {
    title:{
      type: String,
      default: '批量排程提醒'
    },
    subTitle: {
      type: String,
      default: '同一合同下还有以下订单可以进行排程，是否进行批量排程'
    },
    confirmBtnText: {
      type: String,
      default: '排程'
    },
    time:{
      type: String,
      default: ''
    },
    currentDeliver:{
      type:Object,
      default: ()=>({})
    },
    deliverList: {
      type:Array,
      default: ()=>[]
    },
    footerBtnShow: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      rolateList: [],
      selectIds: [],
      isCheckAll: false,
      isIndeterminate: true,
    }
  },
  watch:{
    deliverList: {
      handler(val){
        console.log('watch deliverList')
        val.forEach(e => {
          if (this.selectIds.includes(e.id)){
            e.checked = true
          } else {
            e.checked = false
          }
        })
        this.$set(this,'rolateList',val)
      },
      deep: true
    }
  },
  created(){
    // 默认全部选中
    this.selectIds = []
    // this.deliverList.forEach(e => {
    //   e.checked = true
    //   this.selectIds.push(e.id)
    // })
    this.$set(this,'rolateList',this.deliverList)
  },
  methods:{
    checkAllChange () {
      this.selectIds = this.selectIds.length !== this.rolateList.length ? this.rolateList.map(e=>e.id) : []
      this.isIndeterminate = false
      if (!this.footerBtnShow) {
        this.$emit('confirm',this.selectIds)
      }
    },
    onChange({ id }){
      if (this.selectIds.includes(id)){
        this.selectIds.splice(this.selectIds.indexOf(id),1)
      } else {
        this.selectIds.push(id)
      }
      this.isCheckAll = this.selectIds.length === this.rolateList.length
      this.isIndeterminate = this.selectIds.length > 0 && this.selectIds.length < this.rolateList.length
      if (!this.footerBtnShow) {
        this.$emit('confirm',this.selectIds)
      }
    },
    onCancel(){
      this.$emit('cancel')
    },
    onConfirm(){
      this.$emit('confirm',this.selectIds)
      this.onCancel()
    }
  }
}
</script>
<style scoped lang="less">
.batch-order{
  position: relative;
  min-width: 335px;
  max-height: 80vh;
  width: 100%;
  padding: 12px;
  overflow-y: scroll;
  .current-deliver,.rolate-deliver{
      font-size: 13px;
      margin-bottom: 10px;
      padding: 12px;
      .header{
        display: flex;
        justify-content: space-between;
        .sub-title{
          position: relative;
          padding-left: 8px;
          &::before{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 3px;
            height: 14px;
            background: #b9921a;
            content: "";
          }
        }
      }
      .mb {
        margin-bottom: 20px;
      }
  }
  .sub-tip{
    font-size: 14px;
    margin-bottom: 10px;
  }
  .mb-8{
      margin-bottom: 4px;
  }
  .footer{
    width: 100%;
    height: 96px;
    display: flex;
    position: sticky;
    bottom: 0;
    left: 0;
    align-items: center;
    background: #ffffff;
    box-sizing: border-box;
    padding: 0 16px;
    justify-content: space-between;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    font-size: 16px;
    color: @black;
    // box-shadow: 0px 2px 8px 2px #ddd;
    .btn{
      flex: auto;
      margin-right: 8px;
      border: 1px solid @black;
      border-radius: 100px;
      cursor: pointer;
      &.submit{
        background: @yellow;
        border: none;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
</style>